import Footer from "../components/Footer";
import Header from "../components/Header";
import "../css/pages/Instalaciones.css";
import corridor from "../images/corridor.jpeg";
import front2 from "../images/front2.jpeg";
import room from "../images/room.jpeg";
import room2 from "../images/room2.jpeg";
import room3 from "../images/room3.jpeg";
import patio from "../images/Patio.jpeg";
import patio2 from "../images/Patio2.jpeg";
import arrow from "../images/arrow.png";
import { useState } from "react";

const images = [front2, corridor, room, room2, room3, patio, patio2];

function Instalaciones() {
  const [index, setIndex] = useState(0);
  const [displayRight, setDisplayRight] = useState(null);
  const [displayLeft, setDisplayLeft] = useState(0);

  const onClick = (isRight) => {
    if (isRight) {
      if (index === images.length - 1) return;
      if (index === 0) setDisplayLeft(null);
      if (index === images.length - 2) setDisplayRight(0);
      setIndex(index + 1);
    } else {
      if (index === 0) return;
      if (index === images.length - 1) setDisplayRight(null);
      if (index === 1) setDisplayLeft(0);
      setIndex(index - 1);
    }
  };

  return (
    <div>
      <Header page={"Instalaciones"} />
      <section className="instalaciones">
        <div className="instBox">
          <div className="d-none d-md-flex d-lg-flex justify-content-center align-items-center">
            <div className="d-flex flex-column justify-content-between w-50">
              <p className="instParagraph">
                Nuestras instalaciones cuentan con una variedad de comodidades
                para nuestros clientes, así como también contamos con un amplio
                espacio para asegurar la comodidad de los mismos.
              </p>
              <p className="instParagraph">
                En Mamina ofrecemos un servicio integral de 24 hs diarias los
                365 días del año y nuestros residentes pueden optar entre
                estadías diurnas y/o estadías mensuales.
              </p>
              <p className="instParagraph">
                Contamos con zonas en común y privadas. Nuestro alojamiento
                ofrece habitaciones individuales, dobles y triples, todas
                equipadas con el mobiliario necesario. A su vez, cuentan con TV
                cable , WiFi y aire acondicionado.
              </p>
              <p className="instParagraph">
                Los espacios en común, tanto living como comedor diario, también
                cuentan con lo especificado anteriormente
              </p>
            </div>
            <img
              src={arrow}
              alt="arrow"
              onClick={() => {
                onClick(false);
              }}
              className={`arrow`}
              style={{ opacity: displayLeft }}
            />
            <img src={images[index]} alt="fotoMamina" className="image" />
            <img
              src={arrow}
              alt="arrow"
              onClick={() => {
                onClick(true);
              }}
              className={`arrow right`}
              style={{ opacity: displayRight }}
            />
          </div>
          <div className="d-lg-none d-md-none d-flex flex-column justify-content-center align-items-center w-100">
            <p className="instParagraph">
              Nuestras instalaciones cuentan con una variedad de comodidades
              para nuestros clientes, así como también contamos con un amplio
              espacio para asegurar la comodidad de los mismos.
            </p>
            <p className="instParagraph">
              En Mamina ofrecemos un servicio integral de 24 hs diarias los 365
              días del año y nuestros residentes pueden optar entre estadías
              diurnas y/o estadías mensuales.
            </p>
            <div className="d-flex justify-content-center align-items-center mb-4">
              <img
                src={arrow}
                alt="arrow"
                onClick={() => {
                  onClick(false);
                }}
                className={`arrow`}
                style={{ opacity: displayLeft }}
              />
              <img src={images[index]} alt="fotoMamina" className="image" />
              <img
                src={arrow}
                alt="arrow"
                onClick={() => {
                  onClick(true);
                }}
                className={`arrow right`}
                style={{ opacity: displayRight }}
              />
            </div>
            <p className="instParagraph">
              En nuestra residencia contamos con zonas en común y privadas.
              Nuestro alojamiento ofrece habitaciones individuales, dobles y
              triples, todas equipadas con el mobiliario necesario. A su vez,
              cuentan con TV cable , WiFi y aire acondicionado.
            </p>
            <p className="instParagraph">
              Los espacios en común, tanto living como comedor diario, también
              cuentan con lo especificado anteriormente
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Instalaciones;
