import Footer from "../components/Footer";
import Header from "../components/Header";
import "../css/pages/Menus.css";
import ArrozConEnsalada from "../images/ArrozConEnsalada.jpeg";
import Bizcochos from "../images/Bizcochos.jpeg";
import CarneConGuiso from "../images/CarneConGuiso.jpeg";
import CarneConPure from "../images/CarneConPure.jpeg";
import CarneYEnsalada from "../images/CarneYEnsalada.jpeg";
import CarrotCake from "../images/CarrotCake.jpeg";
import Empanadas from "../images/Empanadas.jpeg";
import MilanesaConNoissete from "../images/MilanesaConNoissetes.jpeg";
import MilanesaConPure from "../images/MilanesaConPure.jpeg";
import Pescado from "../images/Pescado.jpeg";
import PescadoConVerdura from "../images/PescadoConVerduras.jpeg";
import SandwichConPure from "../images/SandwichConPure.jpeg";
import TortaDeFrutilla from "../images/TortaDeFrutilla.jpeg";
import arrow from "../images/arrow.png";
import { useState } from "react";

const imagesAlmuerzo = [
  CarneYEnsalada,
  ArrozConEnsalada,
  CarneConGuiso,
  CarneConPure,
  Empanadas,
  MilanesaConNoissete,
  MilanesaConPure,
  Pescado,
  PescadoConVerdura,
  SandwichConPure,
];

const imagesDesayuno = [TortaDeFrutilla, Bizcochos, CarrotCake];

function Menus() {
  const [indexDesayuno, setIndexDesayuno] = useState(0);
  const [indexAlmuerzo, setIndexAlmuerzo] = useState(0);
  const [displayRightDes, setDisplayRightDes] = useState(null);
  const [displayLeftDes, setDisplayLeftDes] = useState(0);
  const [displayRightAlm, setDisplayRightAlm] = useState(null);
  const [displayLeftAlm, setDisplayLeftAlm] = useState(0);

  const onClickDes = (isRight) => {
    if (isRight) {
      if (indexDesayuno === imagesDesayuno.length - 1) return;
      if (indexDesayuno === 0) setDisplayLeftDes(null);
      if (indexDesayuno === imagesDesayuno.length - 2) setDisplayRightDes(0);
      setIndexDesayuno(indexDesayuno + 1);
    } else {
      if (indexDesayuno === 0) return;
      if (indexDesayuno === imagesDesayuno.length - 1) setDisplayRightDes(null);
      if (indexDesayuno === 1) setDisplayLeftDes(0);
      setIndexDesayuno(indexDesayuno - 1);
    }
  };

  const onClickAlmuerzo = (isRight) => {
    if (isRight) {
      if (indexAlmuerzo === imagesAlmuerzo.length - 1) return;
      if (indexAlmuerzo === 0) setDisplayLeftAlm(null);
      if (indexAlmuerzo === imagesAlmuerzo.length - 2) setDisplayRightAlm(0);
      setIndexAlmuerzo(indexAlmuerzo + 1);
    } else {
      if (indexAlmuerzo === 0) return;
      if (indexAlmuerzo === imagesAlmuerzo.length - 1) setDisplayRightAlm(null);
      if (indexAlmuerzo === 1) setDisplayLeftAlm(0);
      setIndexAlmuerzo(indexAlmuerzo - 1);
    }
  };

  return (
    <div>
      <Header page={"Menus"} />
      <section className="menus">
        <div className="menuBox">
          <div className="menuSections d-flex flex-column align-items-center">
            <h3 className="menuTitles d-flex justify-content-center">
              <strong>Nuestra cocina</strong>
            </h3>
            <p className="menuParagraph">
              A cargo de Héctor Rodríguez, quien elabora y diagrama los menú de
              cada semana. Acompañado de un nutricionista de confianza quien a
              su vez también determina las mejores opciones alimenticias para
              nuestros residentes.
            </p>
          </div>
          <div className="d-flex flex-column align-items-center menuSections">
            <h3 className="menuTitles d-flex justify-content-center">
              <strong>Desayunos</strong>
            </h3>
            <p className="menuParagraph">
              Nuestros desayunos son a base de leche, té, café, cocoa, yogurt,
              acompañados del clásico pan, manteca, dulces, mermeladas y queso,
              así como también una gama variada de tortas caseras como ser:
              chocolate, vainilla, coco, limón, naranja, manzana y canela, pasta
              frola, etc.
            </p>
            <div className="d-flex justify-content-center align-items-center mt-1">
              <img
                src={arrow}
                alt="arrow"
                onClick={() => {
                  onClickDes(false);
                }}
                className="arrow"
                style={{ opacity: displayLeftDes }}
              />
              <img
                src={imagesDesayuno[indexDesayuno]}
                alt="desayuno"
                className="menuImage"
              />
              <img
                src={arrow}
                alt="arrow"
                right
                onClick={() => {
                  onClickDes(true);
                }}
                className="arrow right"
                style={{ opacity: displayRightDes }}
              />
            </div>
          </div>
          <div className="menuSections d-flex flex-column align-items-center">
            <h3 className="menuTitles d-flex justify-content-center">
              <strong>Almuerzos/Cenas</strong>
            </h3>
            <div className="menuParagraphLists row">
              <p className="col-6 col-md-4 col-lg-3 menuLists">
                A base de carne:
                <ul>
                  <li className="mt-2">Milanesas</li>
                  <li>Pastel</li>
                  <li>Pan</li>
                  <li>Brasera</li>
                  <li>Albóndigas</li>
                </ul>
              </p>
              <p className="col-6 col-md-4 col-lg-3 menuLists">
                A base de pollo:
                <ul>
                  <li className="mt-2">Strogonoff</li>
                  <li>Pollo al horno</li>
                  <li>Milanesas</li>
                  <li>Churrascos</li>
                  <li>Salpicón</li>
                </ul>
              </p>
              <p className="col-6 col-md-4 col-lg-3 menuLists">
                A base de cerdo:
                <ul>
                  <li className="mt-2">Bondiola rellena</li>
                  <li>Bifes a la cacerola</li>
                  <li>Milanesas</li>
                  <li>Churrascos</li>
                </ul>
              </p>
              <p className="col-6 col-md-4 col-lg-3 menuLists">
                Tartas:
                <ul>
                  <li className="mt-2">Pascualina</li>
                  <li>Atún</li>
                  <li>Caprese</li>
                  <li>Jamón y queso</li>
                  <li>Puerro</li>
                  <li>Zapallitos</li>
                  <li>Berenjena</li>
                </ul>
              </p>
              <p className="col-6 col-md-4 col-lg-3 menuLists">
                Olla:
                <ul>
                  <li className="mt-2">Guiso de arroz</li>
                  <li>Buseca</li>
                  <li>Guiso de lentejas</li>
                </ul>
              </p>
              <p className="col-6 col-md-4 col-lg-3 menuLists">
                Pastel:
                <ul>
                  <li className="mt-2">Espinaca</li>
                  <li>Zapallitos</li>
                  <li>Carne</li>
                  <li>Pescado</li>
                </ul>
              </p>
              <p className="col-3 menuLists">
                Sopa:
                <ul>
                  <li className="mt-2">Vegetales varios</li>
                </ul>
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-1">
              <img
                src={arrow}
                alt="arrow"
                onClick={() => {
                  onClickAlmuerzo(false);
                }}
                className="arrow"
                style={{ opacity: displayLeftAlm }}
              />
              <img
                src={imagesAlmuerzo[indexAlmuerzo]}
                alt="almuerzo"
                className="menuImage"
              />
              <img
                src={arrow}
                alt="arrow"
                onClick={() => {
                  onClickAlmuerzo(true);
                }}
                className="arrow right"
                style={{ opacity: displayRightAlm }}
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Menus;
