import "../css/components/Header.css";
import { Nav, Navbar, NavLink } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const EMPTY_STRING = "";

function Header({ page }) {
  const [selectedServicios, setSelectedServicios] = useState(EMPTY_STRING);
  const [selectedInstalaciones, setSelectedInstalaciones] =
    useState(EMPTY_STRING);
  const [selectedMenus, setSelectedMenus] = useState(EMPTY_STRING);
  const [selectedAboutUs, setSelectedAboutUs] = useState(EMPTY_STRING);

  useEffect(() => {
    switch (page) {
      case "Servicios":
        setSelectedServicios("selected");
        break;
      case "Instalaciones":
        setSelectedInstalaciones("selected");
        break;
      case "Menus":
        setSelectedMenus("selected");
        break;
      case "Sobre nosotros":
        setSelectedAboutUs("selected");
        break;
      default:
        break;
    }
  }, [page]);

  return (
    <header className="h-20">
      <Navbar collapseOnSelect expand="md">
        <Navbar.Toggle
          aria-controls="navbarScroll"
          data-bs-target="#navbarScroll"
        />
        <Navbar.Collapse className="justify-content-around">
          <Nav>
            <NavLink eventKey="1" as={Link} to="/" className="w-10">
              <p className="logo">Mamina</p>
            </NavLink>
          </Nav>
          <Nav className="d-flex justify-content-center align-items-center">
            <NavLink eventKey="2" as={Link} to="/servicios">
              <p className={`buttons ${selectedServicios}`}>SERVICIOS</p>
            </NavLink>
            <NavLink
              entryKey="3"
              as={Link}
              to="/instalaciones"
              className="d-flex justify-content-center align-items-center"
            >
              <p className={`buttons ${selectedInstalaciones}`}>
                INSTALACIONES
              </p>
            </NavLink>
            <NavLink entryKey="4" as={Link} to="/menus">
              <p className={`buttons ${selectedMenus}`}>MENUS</p>
            </NavLink>
            <NavLink entryKey="5" as={Link} to="/aboutus">
              <p className={`buttons ${selectedAboutUs}`}>NOSOTROS</p>
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

export default Header;
