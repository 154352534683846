import { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import manualidades1 from "../images/manualidades1.jpeg";
import manualidades2 from "../images/manualidades2.jpeg";
import manualidades3 from "../images/manualidades3.jpeg";
import manualidades4 from "../images/manualidades4.jpeg";
import arrow from "../images/arrow.png";
import ejercicio from "../images/ejercicio.webp";
import "../css/pages/Servicios.css";

const images = [manualidades1, manualidades2, manualidades3, manualidades4];

function Servicios() {
  const [index, setIndex] = useState(0);
  const [displayRight, setDisplayRight] = useState(null);
  const [displayLeft, setDisplayLeft] = useState(0);

  const onClick = (isRight) => {
    if (isRight) {
      if (index === images.length - 1) return;
      if (index === 0) setDisplayLeft(null);
      if (index === images.length - 2) setDisplayRight(0);
      setIndex(index + 1);
    } else {
      if (index === 0) return;
      if (index === images.length - 1) setDisplayRight(null);
      if (index === 1) setDisplayLeft(0);
      setIndex(index - 1);
    }
  };
  return (
    <div>
      <Header page={"Servicios"} />
      <section className="servicios p-5">
        <div className="servBox d-flex flex-column align-items-center">
          <h3 className="servTitles text-center">
            <strong>El Equipo Multidisciplinario</strong>
          </h3>
          <p className="servParagraph">
            Mamina está conformado por su director Héctor Rodríguez Bodeant,
            quien a su vez está a cargo de la cocina. <br />
            Nuestro doctor es Gustavo Miquelerena (médico geriátra). <br />
            Con un staff de enfermeras y auxiliares de servicio capacitados para
            el cuidado del adulto mayor.
          </p>
          <h3 className="servTitles text-center">
            <strong>Actividades de Recreacion</strong>
          </h3>
          <p className="servParagraph">
            Ofrecemos tareas recreativas como ser <strong>gimnasia</strong> y{" "}
            <strong>taller de manualidades</strong>. <br />
            La actividad de gimnasia está a cargo del profesor Alejandro Regio,
            quien nos acompaña desde hace varios años, desempeñando tal
            actividad enfocado hacia el uso de la motricidad fina y lo corporal.
          </p>
          <div>
            <img src={ejercicio} alt="deporte" className="servImage" />
          </div>
          <p className="servParagraph">
            El taller de manualidades es llevado a cabo por el equipo de ARTEM,
            el cual semana a semana desarrolla diferentes manualidades las
            cuales una vez terminadas son expuestas en cartelera.
          </p>
          <div>
            <img
              src={arrow}
              alt="arrow"
              className="arrow"
              onClick={() => {
                onClick(false);
              }}
              style={{ opacity: displayLeft }}
            />
            <img src={images[index]} alt="manualidades" className="servImage" />
            <img
              src={arrow}
              alt="arrow"
              className="arrow right"
              onClick={() => {
                onClick(true);
              }}
              style={{ opacity: displayRight }}
            />
          </div>
          <p className="servParagraph">
            También contamos con la presencia de Andrés Soca, nuestro psicólogo,
            quien nos ayuda a acompañar emocionalente cada ingreso a nuestro
            hogar, como también su estadía dentro del mismo.
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Servicios;
