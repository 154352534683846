import Footer from "../components/Footer";
import Header from "../components/Header";
import "../css/pages/Test.css";

function Test() {
  return (
    <>
      <div className="test">
        <h1>Title</h1>
        <p>Paragraph</p>
        <a>Link</a>
      </div>
    </>
  );
}

export default Test;
