import Footer from "../components/Footer";
import Header from "../components/Header";
import Video from "../components/Video";
import "../css/pages/SobreNosotros.css";
import maminaFront from "../images/maminaFront.jpeg";

function SobreNosotros() {
  return (
    <div className="aboutUsContainer">
      <Header page={"Sobre nosotros"} />
      <section className="aboutUs">
        <div className="white">
          <div className="w-100 d-flex justify-content-center mb-2">
            <p className="aboutUsPhrase text-center">
              En Mamina ofrecemos un acompañamiento cálido y constante, con la
              mayor calidad humana, al servicio del adulto mayor
            </p>
          </div>
          <div className="d-flex flex-column flex-md-row flex-lg-row justify-content-around align-items-center firstBox">
            <p className="aboutParagraph inside d-flex justify-content-center align-items-center">
              Nos abocamos enteramente al servicio, dedicados totalmente al
              cuidado del adulto mayor entendiendo principalmente las
              necesidades y/o inquietudes que tienen las personas que nos eligen
              así como también sus familiares
            </p>
            <div className="inside d-flex justify-content-center align-items-center">
              <Video />
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center secondBox">
            <p className="aboutParagraph inside d-flex justify-content-center align-items-center">
              Mamina data de 40 años dedicados a acompañar, cuidar y amar a cada
              adulto mayor que ingresa a nuestro hogar y pasa a ser parte de
              nuestra gran familia.
            </p>
          </div>
          <div className="d-flex flex-column flex-md-row flex-lg-row justify-content-around align-items-center">
            <div className="inside imageContainer">
              <img
                src={maminaFront}
                alt="maminaFront"
                className="maminaFront"
              />
            </div>
            <p className="aboutParagraph inside">
              Nuestro residencial fue fundado en 1983 por Juanita, artífice de
              tal emprendimiento y maestra de cada conocimiento que hoy su hijo
              Héctor Rodríguez puede poner en práctica, dado que desde sus
              jóvenes 26 años se hizo cargo de tal legado, hoy con la
              experiencia adquirida, siente su sueño realizado y vive día a día
              agradecido de lo que Mamina ha significado en su vida.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default SobreNosotros;
