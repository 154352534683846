import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Instalaciones from "./pages/Instalaciones";
import Menus from "./pages/Menus";
import NotFound from "./pages/NotFound";
import SobreNosotros from "./pages/SobreNosotros";
import Servicios from "./pages/Servicios";
import Test from "./pages/Test";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="servicios" element={<Servicios />} />
        <Route path="instalaciones" element={<Instalaciones />} />
        <Route path="menus" element={<Menus />} />
        <Route path="aboutus" element={<SobreNosotros />} />
        <Route path="test" element={<Test />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
