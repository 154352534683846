import Footer from "../components/Footer";
import Header from "../components/Header";
import "../css/pages/Home.css";

function Home() {
  return (
    <div>
      <Header page={"Home"} />
      <section className="d-flex justify-content-center align-items-end homeSection">
        <p className="homeText">
          "Ellos siempre cuidaron de tí, es momento de cuidar de ellos"
        </p>
      </section>
      <Footer />
    </div>
  );
}

export default Home;
