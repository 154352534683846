import maminaVideo from "../assets/maminaVideo.mp4";
import "../css/components/Video.css";

function Video() {
  return (
    <video
      src={maminaVideo}
      autoPlay
      muted
      loop
      playsInline
      controls
      controlsList="nofullscreen"
    />
  );
}

export default Video;
